<template>
  <div class="main">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-card v-for="item in cardList" :key=item.id :price=item.amount currency="¥" :title="item.studentName">
        <template #desc>
          <div class="text"><span>学校名称:</span>{{ item.schoolName }}</div>
          <div class="text"><span>年级名称:</span>{{ item.gradeName }}</div>
          <div class="text"><span>班级名称:</span>{{ item.className }}</div>
          <div class="text"><span>学生名称:</span>{{ item.studentName }}</div>
          <div class="text"><span>学生性别:</span>{{ item.sex == 1 ? "女" : "男" }}</div>
          <!--          <div class="text" v-if="item.summerNum>0">-->
          <!--            <span>夏服:</span>{{ item.summerNum }}-->
          <!--          </div>-->
          <div class="text" v-if="item.autumnNum>0"><span>夏服尺码:</span>{{ item.clothesSize }}</div>
          <!--          <div class="text" v-if="item.autumnNum>0">-->
          <!--            <span>秋服:</span>{{ item.autumnNum }}-->
          <!--          </div>-->
          <div class="text" v-if="item.autumnNum>0"><span>秋服尺码:</span>{{ item.autumnClothesSize }}</div>
          <!--          <div class="text" v-if="item.winterNum>0">-->
          <!--            <span>冬服:</span>{{ item.winterNum }}-->
          <!--          </div>-->
          <div class="text" v-if="item.winterNum>0"><span>冬服尺码:</span>{{ item.winterClothesSize }}</div>
        </template>
        <template #footer>
          <van-button type="info" size="small" v-if="![3].includes(item.refundState) && Date.parse(item.createTime) > new Date().getTime() - 7 * 24 * 60 * 60 * 1000" @click="handleEdit(item)">编辑</van-button>
          <van-button type="danger" size="small" v-if="item.state == 1 &&  [0, 4,5,6,7].includes(item.refundState) && Date.parse(item.createTime) > new Date().getTime() - 7 * 24 * 60 * 60 * 1000" @click="handleRefund(item)">退款</van-button>
        </template>
      </van-card>
    </van-list>

   <!-- <van-dialog v-model="showEditDialog" title="编辑订单" show-cancel-button @confirm="handleEditConfirm"
                @cancel="showEditDialog = false">
      <div class="scrollable-content">
        &lt;!&ndash;  <van-radio-group v-model="orderInfo.sex" >
            <van-cell-group inset title="性别">
              <van-cell title="男" clickable @click="orderInfo.sex = '0'">
                <template #right-icon>
                  <van-radio name="0" />
                </template>
              </van-cell>
              <van-cell title="女" clickable @click="orderInfo.sex = '1'">
                <template #right-icon>
                  <van-radio name="1" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>&ndash;&gt;

        <van-radio-group v-model="orderInfo.classId">
          &lt;!&ndash; <van-radio v-for="item in clothesTypes" :key="item.clothesSize" :name="item.clothesSize">{{ item.clothesSize }}</van-radio> &ndash;&gt;
          <van-cell-group inset title="班级">
            <van-cell v-for="item in classes" :key="item.value" :title="item.text" clickable @click="orderInfo.classId = item.value">
              <template #right-icon>
                <van-radio :name=item.value />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-radio-group v-model="orderInfo.clothesSize">
          <van-cell-group inset title="夏服尺寸">
            <van-cell v-for="item in clothesSizes" :key="item.text" :title="item.text" clickable @click="orderInfo.clothesSize = item.text">
              <template #right-icon>
                <van-radio :name=item.text />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>

        <van-radio-group v-model="orderInfo.autumnClothesSize">
          <van-cell-group inset title="秋服尺寸">
            <van-cell v-for="item in autumnClothesSizes" :key="item.text" :title="item.text" clickable @click="orderInfo.autumnClothesSize = item.text">
              <template #right-icon>
                <van-radio :name=item.text />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-radio-group v-model="orderInfo.winterClothesSize">
          <van-cell-group inset title="冬服尺寸">
            <van-cell v-for="item in winterClothesSizes" :key="item.text" :title="item.text" clickable @click="orderInfo.winterClothesSize = item.text">
              <template #right-icon>
                <van-radio :name=item.text />
              </template>
            </van-cell>
          </van-cell-group>


        </van-radio-group>
      </div>
    </van-dialog>-->

    <!--修改订单-->
    <div class="xiugai" v-show="showEditDialog">
      <van-nav-bar
          title="修改订单"
          left-arrow
          @click-left="showEditDialog = false"
          right-text="确定"
          @click-right="handleEditConfirm"
      />
      <van-cell-group>
        <van-cell required title="所属年级" :value="gradeName" is-link @click="showGrade = true"></van-cell>
      <van-popup v-model="showGrade" round position="bottom">
        <van-picker title="所属年级" :columns="grades" @confirm="gradeConfirm" @cancel="showGrade = false" show-toolbar />
      </van-popup>
        <van-cell required title="班级"  is-link :value="className" @click="showClass = true"></van-cell>
        <van-popup v-model="showClass"  position="bottom">
          <van-picker title="所属班级" :columns="classes" @confirm="classConfirm" @cancel="showClass = false" show-toolbar/>
        </van-popup>
        <van-cell required title="学生性别" :value="clothesSex.text" is-link @click="showSex = true"></van-cell>
      <van-popup v-model="showSex" position="bottom">
        <van-picker title="学生性别" :columns="sexEnum" @confirm="clothesSexConfirm" @cancel="showSex = false"
          show-toolbar />
      </van-popup>
        <van-cell required title="夏季尺码"  is-link :value="orderInfo.clothesSize" @click="showSummer = true"></van-cell>
        <van-popup v-model="showSummer"  position="bottom">
          <van-picker title="夏季尺码" :columns="clothesSizes" @confirm="summerConfirm" @cancel="showSummer = false" show-toolbar/>
        </van-popup>
        <van-cell required title="秋季尺码"  is-link :value="orderInfo.autumnClothesSize" @click="showAutumn = true"></van-cell>
        <van-popup v-model="showAutumn"  position="bottom">
          <van-picker title="秋季尺码" :columns="autumnClothesSizes" @confirm="autumnConfirm" @cancel="showAutumn = false" show-toolbar/>
        </van-popup>
        <van-cell required title="冬季尺码"  is-link :value="orderInfo.winterClothesSize" @click="showWinter = true"></van-cell>
        <van-popup v-model="showWinter"  position="bottom">
          <van-picker title="冬季尺码" :columns="winterClothesSizes" @confirm="winterConfirm" @cancel="showWinter = false" show-toolbar/>
        </van-popup>
      </van-cell-group>
    </div>

    <van-dialog v-model="showRefundDialog" title="申请退款" show-cancel-button @confirm="handleRefundConfirm"
                @cancel="showRefundDialog = false">
      <van-field v-model="refundInfo.reason" type="text" label="退款原因" placeholder="请输入原因"/>
    </van-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { Card, List, NavBar, Toast } from 'vant';

export default {
  name: 'HelloWorld',
  components: {
    'van-list': List,
    'van-card': Card,
    'nav-bar':NavBar,
  },
  data() {
    return {
      gradeData: {},
      classes: [],
      grades: [],
      cardList: [],
      pageNum: 0,
      pageSize: 100,
      finished: false,
      loading: false,

      clothesSizes: [],
      autumnClothesSizes: [],
      winterClothesSizes: [],
      commonClothesSizes: [],

      showEditDialog: false,
      orderInfo: {
        id: 0,
        classId: 0,
        clothesSize: 0,
        winterClothesSize: 0,
        autumnClothesSize: 0,
        sex:-1,
        gradeName : ""
      },

      sexEnum: [
        { "text": "男", "value": 0 },
        { "text": "女", "value": 1 }
      ],

      clothesSex: { "text": "男", "value": 0 },
      showSex: false,
      showGrade: false,
      gradeName:"",
      className : "",
      showRefundDialog: false,
      refundInfo: {
        orderId: 0,
        reason: "",
        userId: localStorage.getItem("userId"),
      },
      /*修改*/
      showClass:false,
      
      showSummer:false,
      
      showAutumn:false,
       
      showWinter:false,
      
    }
  },

  methods: {
    gradeConfirm(value) {
      this.classes = [];
      this.clothes = []
      if (this.gradeName == '' || this.gradeName != value) {
        let classDatas = this.gradeData[value]
        for (var i = 0; i < classDatas.length; i++) {
          var classD = {}
          classD['text'] = classDatas[i].name;
          classD['value'] = classDatas[i].id;
          this.classes.push(classD);
        }
        this.orderInfo.classId = '';
        this.className = '';
        
      }

      this.gradeName = value;
      this.orderInfo.gradeName = value;
      this.showGrade = false;

       
    },

    clothesSexConfirm(node) {
      this.clothesSex = node;
      this.showSex = false;
      this.orderInfo.sex =  this.clothesSex.value;
    },

    classConfirm(value) {
      this.orderInfo.classId = value.value;
      this.className = value.text
      this.showClass = false;
    },

    summerConfirm(value) {
      this.orderInfo.clothesSize = value.text;
      this.showSummer = false;
    },
    autumnConfirm(value) {
      this.orderInfo.autumnClothesSize = value.text;
      this.showAutumn = false;
    },
    winterConfirm(value) {
      this.orderInfo.winterClothesSize = value.text;
      this.showWinter = false;
    },
    handleEditConfirm() {
      if(this.orderInfo.gradeName == ''){
        this.$toast("编辑失败,年级必填")
        retrun
      }
      if(this.orderInfo.classId == ''){
        this.$toast("编辑失败，班级必填")
        return
      }
      // 这里可以进行编辑操作，例如更新服务器数据
      request({
        method: 'POST',
        url: '/order/changeOrderInfo',
        data: this.orderInfo
      }).then((response) => {
        if (response.data.code == "0") {
          this.$toast("编辑成功")
        } else {
          this.$toast("编辑失败")
        }
        this.cardList = []
        this.pageNum = 0
        this.onLoad()
      })
      // 更新完成后，可以关闭弹窗并刷新列表
      this.showEditDialog = false;
      // 假设我们找到了对应的订单项并进行了更新

    },

    handleEdit(row) {
      console.log("row" , row)
      this.showEditDialog = true
      this.orderInfo.id = row.id
      this.orderInfo.classId = row.classId
      this.orderInfo.clothesSize = row.clothesSize
      this.orderInfo.autumnClothesSize = row.autumnClothesSize
      this.orderInfo.winterClothesSize = row.winterClothesSize
      this.orderInfo.gradeName = row.gradeName
      this.orderInfo.sex  = row.sex
      this.clothesSex = { "text": "男", "value": 0 }
      if (1== row.sex) {
       this.clothesSex = {"text": "女", "value": 1 }
      }
      
      this.getClothesSize(row.schoolId)
      this.getClasses(row.schoolId, row.gradeName)
    },

    handleRefund(row) {
      this.showRefundDialog = true
      this.refundInfo.orderId = row.id
    },

    handleRefundConfirm() {

      // 这里可以进行编辑操作，例如更新服务器数据
      request({
        method: 'POST',
        url: '/order/creatRefund',
        data: this.refundInfo
      }).then((response) => {
        if (response.data.code == "0") {
          this.$toast("申请成功")
        } else {
          this.$toast("申请失败")
        }
      })
      // 更新完成后，可以关闭弹窗并刷新列表
      this.showRefundDialog = false;
      // 假设我们找到了对应的订单项并进行了更新
      this.cardList = []
      this.pageNum = 0
      this.onLoad()
    },

    // getClothesSize(schId, gradeName) {
    // 确认编辑订单的处理函数

    // 这里可以进行编辑操作，例如更新服务器数据
    // request({
    //   method: 'POST',
    //   url: '/class-group/getClothesSize',
    //   data: {
    //     "schId": schId,
    //     "gradeName": gradeName,
    //   }
    // }).then((response) => {
    //   if (response.data.code == "0") {
    //     this.clothesTypes = response.data.data
    //   } else {
    //     this.$toast("查询衣服枚举失败")
    //   }
    // })

    // },

    getClothesSize(schId) {
      request({
        method: 'POST',
        url: '/class-group/getClothesSize',
        data: {
          'schId': schId
        }
      }).then((response) => {
        if (response.data.code != 0) {
          Toast(response.message);
          return
        }
        let clothesSizeRep = response.data.data
        for (var i = 0; i < clothesSizeRep.length; i++) {
          var size = {}
          if (clothesSizeRep[i].season == 1) {
            size['text'] = clothesSizeRep[i].clothesSize;
            size['value'] = clothesSizeRep[i].id;
            this.clothesSizes.push(size);
          } else if (clothesSizeRep[i].season == 2) {
            size['text'] = clothesSizeRep[i].clothesSize;
            size['value'] = clothesSizeRep[i].id;
            this.autumnClothesSizes.push(size);
          } else if (clothesSizeRep[i].season == 3) {
            size['text'] = clothesSizeRep[i].clothesSize;
            size['value'] = clothesSizeRep[i].id;
            this.winterClothesSizes.push(size);
          } else if (clothesSizeRep[i].season == 9) {
            console.log("commonClothesSizes")
            size['text'] = clothesSizeRep[i].clothesSize;
            size['value'] = clothesSizeRep[i].id;
            this.commonClothesSizes.push(size);
          }
        }
        if (this.clothesSizes.length == 0) {
          this.clothesSizes = this.commonClothesSizes
        }
        if (this.autumnClothesSizes.length == 0) {
          this.autumnClothesSizes = this.commonClothesSizes
        }
        if (this.winterClothesSizes.length == 0) {
          this.winterClothesSizes = this.commonClothesSizes
        }
      })
    },




    getClasses(schId, gradeName) {
      request({
        method: 'GET',
        url: '/class-group/getClassTree/' + schId,
      }).then((response) => {
        this.gradeData = response.data.data;
        console.log("gradeDatas" , this.gradeData)
        let keys = Object.keys(this.gradeData);
        console.log("111" + keys)
        for (var i = 0; i < keys.length; i++) {
          let node = this.gradeData[keys[i]]
          // console.log("node:"+node)
          this.grades[node[0].oldId - 1] = node[0].gradeName;
        }

        this.gradeName = gradeName
        let classesOrigin = this.gradeData[gradeName];
        this.classes = classesOrigin.map(item => ({
          text: item.name,
          value: item.id
        }));

        for (var i = 0; i < this.classes.length; i++) {
          if(this.orderInfo.classId == this.classes[i].value){
            this.className  = this.classes[i].text
          }
         
        }
      })
    },

    /*确认修改订单*/
    // onClickRight(){
    //   this.showEditDialog = false
    // },
    onLoad() {
      this.pageNum = this.pageNum + 1
       request({
         method: 'POST',
         url: '/order/pageOrderByUser',
         data: {
           "pageNum": this.pageNum,
           "pageSize": this.pageSize,
           "userId": localStorage.getItem("userId"),
         }
       }).then((response) => {
         let data = []
         if (response.data.code == "0") {
          if(response.data.data.list == null){
            
          }else{
           data = response.data.data.list
          }
         } else {
           this.$toast("查询订单失败")
         }
         if (data.length < 1) {
           this.finished = true
         } else {
           this.cardList = this.cardList.concat(data)
           console.log(this.cardList)
         }
       });

      this.loading = false;

    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageNum = 0
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
      this.refreshing = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  background: #eee;
  padding: 5px;
  height: 100%;
}

.van-card__title {
  display: none;
}

.main .text {
  font-size: 14px;
  margin-bottom: 5px;
}

.main .text span {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
}

.van-card {
  border-radius: 5px;
  box-shadow: #a19d9d 2px 3px 8px;
}

.scrollable-content {
  max-height: 300px; /* 设置一个合适的高度 */
  overflow-y: auto; /* 当内容超出高度时，显示垂直滚动条 */
}

/*修改订单*/
.xiugai{
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
}

</style>
